import React from "react"
import { Box } from "reflexbox"
import { Privacy } from "../components/Privacy/japanese"
import { Footer } from "../components/Footer"
import SEO from "../components/seo"

import Layout from "../components/layout"

const PrivacyPolicyPage = () => {
  return (
    <>
      <SEO />
      <Layout showHeaderLaunchButton={false}>
        <Box flex={1}>
          <Privacy />
          <Footer showCTA={false} />
        </Box>
      </Layout>
    </>
  )
}

export default PrivacyPolicyPage
