import React from "react"
import { SafeArea } from "../SafeArea"
import {
  Root,
  Slice,
  Container,
  PrivacyContainer,
  Header,
  TextHeader,
  Subheader,
  TextContainer,
} from "./styled"

type PrivacyProps = {}

export const Privacy: React.FC<PrivacyProps> = props => {
  return (
    <Root flexWrap="wrap" flex={1}>
      <Slice />
      <SafeArea>
        <Container
          height="100%"
          flexWrap="wrap"
          justifyContent="left"
          alignItems="left"
        >
          <Header>
            <TextHeader>Slalom SparkThink プライバシーポリシー</TextHeader>
          </Header>
          <PrivacyContainer>
            <TextContainer>
              <p>
                このプライバシーポリシー (「<b>SparkThink Privacy Policy</b>」)
                は、Slalom, LLC (Slalom Consulting) およびその子会社 (「
                <b>Slalom</b>」または「当社」) が SparkThink アンケートツール
                (「<b>SparkThink</b>」) に関して公開しています。
              </p>
            </TextContainer>
            <TextContainer>
              <p>
                このポリシーは (当社の{" "}
                <a href="https://www.slalom.com/ja/cookie-policy/?lp=1">
                  Cookie ポリシー
                </a>
                およびそこで参照されている他のドキュメントと併せて)、Slalom が
                SparkThink によるアンケート調査を実施する際に SparkThink
                を通じてユーザーから収集する個人データや、Slalom
                からのアンケート調査に回答する際に SparkThink を通じてユーザーが
                Slalom
                に提供する個人データを当社が処理する際の基準を定めています。
                <b>
                  このポリシーは、当社がオフラインで収集する個人データや他のオンラインサイト
                  (当社のウェブサイト{" "}
                  <a href="https://www.slalom.com">www.slalom.com</a> など)
                  を通じて収集する個人データには<u>適用されません</u>
                  。当社が第三者に代わってアンケート調査を実施する際のクライアントのプライバシーに関するプラクティスについては、アンケート調査のリンクを提供した事業体
                  (リンクを Slalom から直接受け取っていない場合)
                  またはアンケート調査を実施する事業体として指定されている事業体のプライバシーポリシーおよび通知を参照してください。
                </b>
              </p>
            </TextContainer>
            <TextContainer>
              <p>
                <em>
                  欧州経済領域、英国、またはスイスの居住者は、このポリシーへの補足として、EEA+
                  データ保護法に基づく開示事項を<a href="#eea">こちら</a>
                  で参照してください。
                </em>
              </p>
              <p>
                <em>
                  米国カリフォルニア州の居住者は、収集時におけるカリフォルニア州消費者プライバシー法通知を
                  <a href="#ccpa">こちら</a>で参照してください。
                </em>
              </p>
              <p>
                <em>
                  メキシコの居住者は、このポリシーへの補足として、メキシコのデータ保護法に基づく開示事項を
                  <a href="#msdp">こちら</a>で参照してください。
                </em>
              </p>
            </TextContainer>
            <TextContainer>
              <p>
                ユーザーの個人データに関する当社の見解およびプラクティスと、当社における個人データの取り扱いについて、ご理解いただけるよう以下に説明を記載していますので、よくお読みください。SparkThink
                ツールを使用することにより、このポリシーに記載されている処理を承諾し、これに同意したものとみなされます。
              </p>
            </TextContainer>
            <TextContainer>
              <p>
                Slalom
                により実施されているアンケート調査をユーザーが受ける場合、ユーザーのデータは
                Slalom, LLC (米国コロラド州の法律に基づき設立され、821 2nd
                Avenue, Suite 1900, Seattle, WA 98104
                に本社を置く米国の有限責任会社)
                によって収集および管理されます。Slalom, LLC は、そのデータを
                Slalom グループ内の企業または実施された SparkThink
                アンケートに関連のある Slalom 企業、あるいはその両方 (「
                <b>Slalom 関連会社</b>」)
                と共有します。たとえば、英国のアンケート調査にご協力いただいた場合、ユーザーの個人データは上記の目的で
                Slalom Consulting Limited (英国の法人)
                と共有される場合があります。このような場合、Slalom, LLC および
                Slalom 関連会社は双方とも、適用法 (EU 一般データ保護規則
                2016/679、その国内施行法および 2018 年データ保護法など (「
                <b>プライバシー法</b>」))
                に基づき、ユーザーのデータの管理者とみなされる場合があります。当社および
                Slalom
                関連会社は、このプライバシーポリシーに記載されている目的で、ユーザーの個人データを
                Slalom
                グループ内および当社の他の子会社との間で、必要に応じて共有する場合があります。
              </p>
            </TextContainer>
            <Subheader>ユーザーから収集する情報 </Subheader>
            <TextContainer>
              <p>
                当社は、ユーザーに関する以下のデータを収集および処理します。
              </p>
              <ul>
                <li>
                  <b>ユーザーから当社に提供される情報。</b> これは、Slalom
                  によるアンケート調査に回答することでユーザーから提供される、ユーザーに関する情報です。ユーザーから提供される情報には、ユーザーの氏名、住所、E
                  メールアドレス、電話番号のほか、ユーザーが提供を選択したその他の情報が含まれる場合があります。このような個人データの提供は任意です。ただし、このような個人データをご提供いただけないと、ユーザーからのご回答をアンケート結果に含めることができない場合や、ユーザーが求める情報またはサービスを当社から提供できない場合があります。ユーザーが当社に提供する個人データは、ユーザーが能動的に当社に送信するものであるため、ユーザーはどのような個人データを提供したかを把握できます。対象となる個人データは、ユーザーが
                  SparkThink
                  ツールを使用して受けるアンケート調査によって異なります。
                </li>
                <li>
                  <b>ユーザーに関して収集される情報。</b>
                  ユーザーから能動的に提供される個人データに加えて、Slalom が
                  SparkThink
                  ツールのパフォーマンスを監視および維持し、サイトに関連する傾向、使用状況、および活動を分析するために、ユーザーから特定の情報を
                  SparkThink
                  によって自動的に収集、処理、および保存することがあります。
                  <ul>
                    <li>
                      技術的な情報。これには、ユーザーがインターネットにアクセスする際のアクセス元ドメインおよびホスト名、コンピュータをインターネットに接続するために使用する
                      IP (インターネットプロトコル)
                      アドレス、ブラウザの種類とバージョン、タイムゾーンと国の設定、ブラウザプラグインの種類とバージョン、オペレーティングシステムおよびプラットフォームなどが含まれます。
                    </li>
                    <li>
                      ユーザーによるアクセスに関する情報。これには、完全な URL
                      (Uniform Resource
                      Locator)、当社サイトを起点、経由、または目的のアクセス先とするクリックストリーム
                      (日時を含む)
                      を含む、ユーザーが閲覧または検索したサービス、ページ応答時間、ダウンロードエラー、特定ページでの滞在時間の長さ、ページでの操作情報
                      (スクロール、クリック、マウスオーバーなど)、ページから離れるために使用された方法などが含まれます。
                    </li>
                  </ul>
                </li>
                <li>
                  <b>他のソースから当社が受け取る情報。</b>
                  当社は、緊密に連携している第三者
                  (マーケティングサービスや人材紹介会社など)
                  から、ユーザーに関する追加情報の提供を受ける可能性があります。
                </li>
              </ul>
            </TextContainer>
            <Subheader>Cookies</Subheader>
            <TextContainer>
              <p>
                SparkThink では、ユーザーと SparkThink
                の他のユーザーを区別するために Cookie
                を使用しています。これによって当社は、SparkThink
                を使用するユーザーに優れたエクスペリエンスを提供し、SparkThink
                をカスタマイズおよび改善することができます。当社が使用する
                Cookie とその使用目的の詳細については、当社の{" "}
                <a href="https://www.slalom.com/ja/cookie-policy/?lp=1">
                  Cookie ポリシー
                </a>
                を参照してください。一部の Web ブラウザーには、Do Not Track
                (「DNT」)
                機能または設定が含まれています。ユーザーはこれを有効にすることで、ご自身のオンライン参照アクティビティに関するデータが監視および収集されないように求めるプライバシー設定を通知することができます。
                <u>当社のプライバシー設定センター</u>では、Cookie
                および類似の技術に関するオプションを指定することにより、ユーザーはオンラインでの追跡を望まないという選択を当社に通知できます。ただし当社では、DNT
                信号への応答を行っていません。 情報の用途
              </p>
            </TextContainer>
            <Subheader>情報の用途</Subheader>
            <TextContainer>
              <p>
                当社は、ユーザーについて保有している情報を以下のように使用します。
              </p>
              <ul>
                <li>
                  <b>ユーザーから当社に提供される情報。</b>{" "}
                  当社は、この情報を以下の目的で使用します。
                  <ul>
                    <li>
                      ユーザーと当社との間で締結された契約から生じる当社の義務を履行し、ユーザーから求められた情報、製品、およびサービスを提供するため。
                    </li>
                    <li>
                      ユーザーが既に購入または問い合わせた商品と類似した、当社が提供するその他の商品およびサービスに関する情報をユーザーに提供するため。
                    </li>
                    <li>
                      ユーザーが関心を寄せる可能性があると当社が考える商品またはサービスに関する情報を、ユーザーに提供するため、またはユーザーに提供することを特定の第三者に許可するため。電子的な手段
                      (E メールまたは SMS)
                      による当社からユーザーへのご連絡は、ユーザーへの以前の販売
                      (または販売交渉)
                      の対象であった商品またはサービスに類似する商品およびサービスについての情報を伝える場合や、ユーザーが同意した場合など、当社が合法的にご連絡できる場合のみに限定されます。
                    </li>
                    <li>サービスの変更についてユーザーに通知するため。</li>
                    <li>
                      SparkThink
                      のコンテンツが、ユーザーおよびユーザーのコンピューターに対して最も効果的に提示されるようにするため。
                    </li>
                  </ul>
                </li>
                <li>
                  <b>ユーザーに関して収集される情報。</b>
                  当社は、この情報を以下の目的で使用します
                  <ul>
                    <li>
                      SparkThink の管理および社内業務
                      (トラブルシューティング、データ分析、テスト、調査、統計およびアンケートの目的を含む)
                      のため。
                    </li>
                    <li>
                      コンテンツがユーザーおよびユーザーのコンピューターに対して最も効果的に提示されるように、SparkThink
                      を改善するため。
                    </li>
                    <li>
                      SparkThink の安心と安全を維持する取り組みの一環として。
                    </li>
                    <li>
                      ユーザーまたは SparkThink
                      の他のユーザーが関心を持つ可能性のある商品またはサービスについて、ユーザーおよび他のユーザーに提案および推奨を行うため。
                    </li>
                  </ul>
                </li>
                <li>
                  <b>他のソースから当社が受け取る情報。 </b>
                  当社は、この情報と、ユーザーから提供された情報および当社がユーザーに関して収集する情報を組み合わせます。当社は上記の目的のために、この情報および組み合わせた情報を
                  (当社が受け取る情報の種類に応じて) 使用します。
                </li>
              </ul>
            </TextContainer>
            <Subheader>ユーザーの情報の開示</Subheader>
            <TextContainer>
              <p>
                ユーザーは、当社がユーザーの個人情報を以下の関係者と共有する権利を持つことに同意するものとします。
              </p>
              <ul>
                <li>当社のグループ企業、すなわち子会社。</li>
                <li>
                  以下を含む特定の第三者。
                  <ul>
                    <li>
                      Slalom
                      のデータ処理者としての役割を担うサービスプロバイダー。このようなサービスプロバイダーは、個人データを保護するための技術的および組織的安全管理措置を実施し、指示通りにのみ個人データを処理する契約上の義務を負います。また、このような第三者のサービスプロバイダーは、当社の情報セキュリティ対策の評価、SparkThink
                      の保守、SparkThink トラフィックの分析、当社および
                      SparkThink
                      ユーザーへのサービスと情報の提供において、当社を支援します。
                    </li>
                    <li>
                      SparkThink
                      の改善および最適化を支援する分析および検索エンジンプロバイダー。
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                当社は、以下の場合にも、第三者にユーザーの個人情報を開示します。
              </p>
              <ul>
                <li>
                  当社が事業または資産を売却または購入する場合に、対象の事業または資産の売主候補者または買主候補者にユーザーの個人データが開示されます。
                </li>
                <li>
                  Slalom,
                  LLC、その子会社、または事実上これらすべての資産が第三者によって取得された場合、当社のユーザーに関する個人データは、譲渡される資産の一部になります。
                </li>
                <li>
                  当社が、法的義務を遵守するため、または他の契約を履行または適用するために、ユーザーの個人データを開示または共有する義務を当社が負っている場合。または、Slalom,
                  LLC
                  もしくはその子会社、当社の従業員や顧客などの権利、財産、安全を保護するために、ユーザーの個人データを開示または共有する義務を当社が負っている場合。
                </li>
              </ul>
            </TextContainer>
            <Subheader>国境を越える開示</Subheader>
            <TextContainer>
              <p>
                ユーザーの個人データは、当社が事業を展開している米国その他の国で、当社または当社の子会社との雇用関係または提携関係があり、ユーザーの居住国以外で業務を行う従業員、代表者、その他の第三者に開示される場合があります。また、当社が事業を展開している米国その他の国を含む、ユーザーの居住国以外に所在する当社のサービスプロバイダーに開示される場合もあります。これらの人物および第三者は、ユーザーの居住国以外で個人データを保存、移転、またはアクセスする可能性があります。
              </p>
              <p>
                ユーザーの個人データを海外に開示する場合、当社は、適用法に従ってユーザーの個人データが保護されるよう海外の受領者に要求するために、状況に応じて合理的な措置を講じます。
              </p>
            </TextContainer>
            <Subheader>お問い合わせ先</Subheader>
            <TextContainer>
              <p>
                ユーザーの個人データは、当社が事業を展開している米国その他の国で、当社または当社の子会社との雇用関係または提携関係があり、ユーザーの居住国以外で業務を行う従業員、代表者、その他の第三者に開示される場合があります。また、当社が事業を展開している米国その他の国を含む、ユーザーの居住国以外に所在する当社のサービスプロバイダーに開示される場合もあります。これらの人物および第三者は、ユーザーの居住国以外で個人データを保存、移転、またはアクセスする可能性があります。
              </p>
              <p>
                ユーザーの個人データを海外に開示する場合、当社は、適用法に従ってユーザーの個人データが保護されるよう海外の受領者に要求するために、状況に応じて合理的な措置を講じます。
              </p>
              <p>
                このプライバシーポリシーについてご質問、ご意見、ご要望がありましたら、E
                メール (
                <a href="mailto:privacy@slalom.com">privacy@slalom.com</a>)
                または郵便 (Slalom, LLC, 821 2nd Avenue, Suite 1900, Seattle, WA
                98104)
                までお問い合わせください。当社のプライバシー管理に関してご不満な点につきましても、同じ連絡先にて承ります。また、ユーザーは、ご自身の司法管轄区域の関連するプライバシー規制当局に苦情を申し立てることもできます。
              </p>
            </TextContainer>
            <Subheader>SparkThink プライバシーポリシーへの変更</Subheader>
            <TextContainer>
              <p>
                このプライバシーポリシーは、必要に応じて変更されることがあり、これには重要な変更も含まれます。SparkThink
                のご利用ごとに、プライバシーポリシーの変更をご確認ください。
              </p>
              <p>発効日: 2022 年 8 月 1 日</p>
            </TextContainer>
            <Subheader id="eea">
              EEA+ データ保護法に基づく補足的な開示
            </Subheader>
            <TextContainer>
              <p>
                Slalom は、欧州経済領域、英国、およびスイス (EEA+)
                の居住者に、SparkThink
                のプライバシーポリシーへの補足として以下の開示事項を提供します。
              </p>
              <p>
                データ管理者: データ管理者は Slalom です (連絡先は上に記載)。
              </p>
              <p>
                <u>処理の法的根拠 </u>(欧州連合および英国の法律に基づく): Slalom
                によるユーザーの個人データの処理に対する法的根拠は以下のとおりです。(i)
                SparkThink
                を通じてユーザーから能動的に提供される個人データについて、ユーザーと締結した
                SparkThink の使用に関する契約  (GDPR 第 6 条 1 項 (b))、(ii)
                SparkThink のパフォーマンスを監視および維持し、SparkThink
                に関連する傾向、使用状況、および活動を分析する目的で、SparkThink
                を通じて受動的に収集された個人データに対する当社の正当な利益
                 (GDPR 第 6 条 1 項 (f))、(iii)
                内部管理およびサポートを目的とする、企業グループ内でのユーザーの個人データの移転に対する当社の正当な利益
                 (GDPR 第 6 条 1 項 (f))
                (アクセスは、対象の情報を必要とする従業員に限定)、(iv)
                法執行機関、政府当局、弁護士、外部コンサルタントへの個人データの送信について
                Slalom が負う法的義務の遵守  (GDPR 第 6 条 1 項
                (c))、あるいは法的請求の行使または防御などの正当な利益、(v)
                当社の組織または資産の全部または一部の譲渡、すなわち当社の事業の全部または一部の秩序立った移行に関連するユーザーの個人データの譲渡に対する当社の正当な利益
                 (GDPR 第 6 条 1 項 (b))、(vi)
                当社または第三者の権利または財産を保護および防御するための当社の正当な利益。これには、あらゆる個人の財産、権利、安全を保護して詐欺行為を防止することを目的とした、契約、ポリシー、利用規約の施行が含まれ、緊急時には従業員または個人の安全保護も含まれます。
              </p>
              <p>
                <u>第三国の受領者:</u>{" "}
                当社が収集または提供を受けるお客様の個人データは、EEA+
                の内外に所在し、EEA+
                の法の観点から十分なレベルのデータ保護を提供していると認められていない受領者に移転され、処理される場合があります。このような第三者は通常、クラウドホスティングおよびインターネットサービスのプロバイダー、オペレーティングシステムおよびプラットフォームのプロバイダー、または特定の
                <a href="https://www.slalom.com/platforms">
                  テクノロジーパートナー
                </a>
                です。SparkThink ツールを操作する場合または Slalom, LLC
                に連絡する場合に、ユーザーは個人データを米国に移転します。このとき
                Slalom, LLC
                は、ユーザーの個人データを英国、オーストラリア、日本、カナダ、ドイツ、スイス、アイルランド、メキシコ、ニュージーランドに移転する場合があります。スイスから英国、ドイツ、カナダ、ニュージーランド、アイルランドへの移転では、追加の保護措置が求められません。スイスからオーストラリア、日本、メキシコ、米国への移転は、ユーザーの同意に基づいて行われます。EU
                または英国の法の観点から十分なレベルのデータ保護を提供していない国にユーザーの個人データを移転する場合、当社は、欧州委員会が採択した標準データ保護条項など、適切な保護措置
                (GDPR/英国 GDPR 第 46 条)
                に基づいて、それぞれの移転を行います。ユーザーは、「連絡先」の項の記載に従って当社に連絡することにより、適切な保護措置の写しを請求できます。
              </p>
              <p>
                <u>データ保管期間:</u>{" "}
                ユーザーの個人データは、要請されたサービスを提供するために必要な期間
                (ほとんどの場合は 5 年以内) に限って保持されます。Slalom
                が契約上または法律上の義務を遵守するためにユーザーの個人データを使用する必要がなくなった場合、当社は、その個人データを当社のシステムおよび記録から削除するか、ユーザーを特定できないように適切に匿名化するための措置を講じるか、その両方を行います。ただし、当社が、その後、法的に規定された期間、税務、監査、および法令遵守のために、個人データを含むユーザーの情報を保持する必要がある場合、または出訴期間内は当社が証拠を保持する必要がある場合は、この限りではありません。
              </p>
              <p>データ主体の権利: </p>
              <p>
                適用法に基づき、ユーザーには、ご自身の個人データに関して以下の権利が認められる場合があります。これらは、適用されるデータ保護法に基づいて制限される場合があります。
              </p>
              <p>
                (i) 個人データへのアクセスを当社に要求する権利:
                ユーザーには、ご自身の個人情報が処理されているかどうかを当社に確認し、処理されている場合は、処理された個人情報のカテゴリ、処理の目的、受領者または受領者のカテゴリを含めて、その個人情報へのアクセスを要求する権利があります。ユーザーには、処理中の個人データのコピーを取得する権利があります。ただし、これは絶対的な権利ではなく、他の個人の利益によってユーザーによるアクセスの権利が制限されることがあります。
              </p>
              <p>
                (ii) 訂正の権利:
                ユーザーには、ご自身に関する不正確または不完全な個人情報があった場合に、その訂正を当社に要請する権利があります。処理の目的に応じて、補足説明の提供などにより、不完全な個人データを完成させる権利が認められる場合があります。
              </p>
              <p>
                (iii) 個人データを消去する権利 (忘れられる権利):
                ユーザーには、ご自身に関する個人データを消去するよう当社に要請する権利があります。
              </p>
              <p>
                (iv) 処理を制限する権利:
                限定的な状況で、ユーザーには、ご自身の個人データの処理を制限するよう当社に要請する権利があります。
              </p>
              <p>
                (v) データポータビリティの権利:
                ユーザーには、ご自身が当社に提供したご自身に関する個人データを受け取る権利が認められる場合があり、その際に受け取るデータは、一般的に使用されている、機械で読み取り可能な、構造化された形式とします。また、当社から妨害されることなく、その個人データを別の事業体に送信する権利が認められる場合があります。
              </p>
              <p>
                <b>
                  vi) 異議を唱える権利:
                  ユーザーには特定の状況下で、ユーザーの特定の状況に関連する理由により、ご自身の個人データの処理
                  (当社によるプロファイリングを含む)
                  にいつでも異議を唱える権利が認められる場合があり、当社はそれ以降ユーザーの個人データを処理しないよう要求される場合があります。
                </b>
              </p>
              <p>
                <b>
                  さらに、ユーザーの個人データがダイレクトマーケティングの目的で処理されている場合、ユーザーには、そのようなダイレクトマーケティングに関連する範囲でのプロファイリングを含めて、そのようなマーケティングを目的としてご自身に関する個人データが処理されることに対し、いつでも異議を唱える権利があります。その場合、当社はそれ以降、そのような目的でユーザーの個人データの処理を行いません。
                </b>
                <br />
                居住地によっては、監督機関に苦情を申し立てる権利が認められる場合があります。ユーザーは、個人データの処理活動について同意を表明した場合も、将来に対して有効な形でこの同意をいつでも撤回できます。そのような撤回は、同意の撤回前の処理の合法性には影響しません。ユーザーは、「連絡先」の項の記載に従って当社に連絡することにより、権利を行使できます
              </p>
              <p>
                <u>個人データの提供拒否: </u>
                ユーザーの個人データを収集することが法律によって義務付けられている場合や、当社とユーザーの間で締結された契約条件に基づいて当社がユーザーの個人データを収集する必要がある場合、当社からの要求時にユーザーからその個人データをご提供いただけない場合、当社とユーザーの間で締結されている契約または締結しようとしている契約を履行できない可能性があります。これは、ユーザーが当社に要求したサービスを提供するために当社が必要とする個人データをユーザーからご提供いただけない場合にも適用されることがあります。その場合は、ユーザーへの関連サービスの提供を取り消す必要が生じることがあり、そのような場合は当社がユーザーに通知するものとします。
              </p>
              <p>
                参考情報:
                <ul>
                  <li>
                    欧州での当社の事業に関するお問い合わせについては通常、2
                    London Bridge, 2nd Floor East, London, SE1 9RA, United
                    Kingdom
                    に登記上の事務所を置き、イングランドおよびウェールズで会社番号
                    08874662 で設立および登記された会社である Slalom Consulting
                    Limited が対応します。
                  </li>
                  <li>
                    カナダでの当社の事業に関するお問い合わせについては通常、745
                    Thurlow Street, Suite 2400, Vancouver, BC V6E 0C5, Canada
                    に登記上の事務所を置き、ブリティッシュコロンビア州の法律に基づいて設立されたカナダの無限責任会社である
                    Slalom Consulting ULC が対応します。
                  </li>
                  <li>
                    米国およびメキシコでの当社の事業に関するお問い合わせについては通常、821
                    2nd Avenue, Suite 1900, Seattle, WA 98104, USA
                    に本社を置き、米国コロラド州の法律に基づいて設立された有限責任会社である
                    Slalom, LLC が対応します。
                  </li>
                  <li>
                    日本での当社の事業に関するお問い合わせについては通常、〒105-6207
                    港区愛宕二丁目５番１号 愛宕グリーンヒルズMORIタワー
                    7階に登記上の事務所を置く日本法人である Slalom
                    株式会社が対応します。
                  </li>
                  <li>
                    オーストラリアでの当社の事業に関するお問い合わせについては通常、Level
                    17, 100 Queen Street, Melbourne Victoria 30000, Australia
                    に登記上の事務所を置き、オーストラリアで会社番号 635 861 004
                    で設立および登記された Slalom Australia Pty Ltd
                    が対応します。
                  </li>
                  <li>
                    ニュージーランドでの当社の事業に関するお問い合わせについては通常、Level
                    22 Floor, PWC Tower, 15 Customs Street West, Auckland
                    Central, Auckland 1010, New Zealand
                    に登記上の事務所を置き、ニュージーランドで会社番号 8244300
                    で設立および登記された Slalom New Zealand Ltd が対応します。
                  </li>
                </ul>
                ただし、当社から別途の通知をした場合は、この限りではありません。
              </p>
            </TextContainer>
            <Subheader>日本のデータ保護法に基づく補足的な開示</Subheader>
            <TextContainer>
              <p>
                <b>1. 関連する Slalom 事業体の詳細</b>
              </p>
              <p>
                日本で個々の個人情報を定期的に処理する日本国内の Slalom
                事業体の代表者の住所と氏名は次のとおりです。
              </p>
              <p>
                Slalom, LLC
                <br />
                821 2nd Avenue, Suite 1900, Seattle, WA 98104
                <br />
                会社代表者: Christopher
                Burger、情報セキュリティおよびガバナンス担当副社長、
                <a href="mailto:privacy@slalom.com">privacy@slalom.com</a>。
              </p>
              <p>
                Slalom 株式会社
                <br />
                〒105-6207 東京都港区愛宕二丁目５番１号
                愛宕グリーンヒルズMORIタワー 7階会社代表者:代表取締役浅見光{" "}
                <a href="mailto:privacy@slalom.com">privacy@slalom.com</a>.
              </p>
              <p>
                その他の事業体に関する詳細については、E メール{" "}
                <a href="mailto:privacy@slalom.com">privacy@slalom.com</a>{" "}
                または郵便 (Slalom Consulting, Privacy Office, 821 2nd Avenue,
                Suite 1900, Seattle, WA 98104) でお問い合わせください。
              </p>
            </TextContainer>
            <TextContainer>
              <p>
                <b>2. Slalom グループ内での個人データの共同利用</b>
              </p>
              <p>
                当社では、このポリシーに記載されているユーザーの個人データを他の
                Slalom の事業体と共有します (以下に示す Slalom
                の事業体のリストを参照してください)。Slalom
                の各事業体では、「情報の用途」の項に記載されている目的で個人データを使用します。個人データの管理責任者となる事業体は、上記の
                Slalom 株式会社です。
              </p>
              <p>
                <b>Slalom 事業体リスト</b>
                <br />
                Slalom, LLC
                <br />
                Slalom Consulting, ULC
                <br />
                Slalom Consulting Ltd.
                <br />
                Slalom Australia Pty Ltd.
                <br />
                Slalom Netherlands B.V.
                <br />
                Slalom GmbH
                <br />
                Slalom Limited
                <br />
                Slalom New Zealand Limited
                <br />
                Slalom Switzerland GmbH
                <br />
                Slalom Technology and Management Solutions Limited
                <br />
                Slalom Consulting Mexico S.R.L. de C.V.
              </p>
            </TextContainer>
            <Subheader id="ccpa">
              収集時におけるカリフォルニア州消費者プライバシー法通知
            </Subheader>
            <TextContainer>
              <p>
                Slalom
                は、オンラインで収集する際のカリフォルニア州消費者プライバシー法
                (「<b>CCPA</b>」) 通知において、当社が SparkThink
                ツールで実施するアンケート調査にカリフォルニア州の居住者が回答した場合、Slalom
                がカリフォルニア州の居住者から収集した情報に関して、オンライン収集の時点またはそれより前に、カリフォルニア州民法第
                1798.100
                条およびカリフォルニア州司法長官規則に基づく開示要件に対処します。
                <b>
                  この「オンラインで収集する際の CCPA
                  通知」は、当社がオフラインで収集する個人データや他のオンラインサイト
                  (当社のウェブサイト{" "}
                  <a href="https://www.slalom.com">www.slalom.com</a> など)
                  を通じて収集する個人データには適用されません。当社が第三者に代わってアンケート調査を実施する際のクライアントのプライバシーに関するプラクティスについては、アンケート調査のリンクを提供した事業体
                  (リンクを Slalom から直接受け取っていない場合)
                  またはアンケート調査を実施する事業体として指定されている事業体のプライバシーポリシーおよび通知を参照してください。
                </b>
              </p>
              <p>
                当社は、以下の事業上または商業上の目的で、消費者に関する以下のカテゴリの個人情報を収集します。
              </p>
              <p>
                ユーザーが SparkThink
                アンケートツールを通じてアンケート調査に回答する際に提供する情報。ユーザーから提供される情報には、ユーザーの氏名、E
                メールアドレス、電話番号のほか、ユーザーが提供を選択したその他の情報が含まれる場合があります。このような個人データの提供は任意です。ただし、このような個人データをご提供いただけない場合、ユーザーから求められた情報やサービスを当社から提供することができません。ユーザーが当社に提供する個人データは、ユーザーが能動的に当社に送信するものであるため、ユーザーはどのような個人データを提供したかを把握できます。対象となる個人データは、ユーザーが
                SparkThink
                ツールを使用して受けるアンケート調査によって異なります。当社は、ユーザーから能動的に提供された情報を以下の目的で使用します。
                <ul>
                  <li>
                    •
                    ユーザーと当社との間で締結された契約から生じる当社の義務を履行し、ユーザーから求められた情報、製品、およびサービスを提供するため。
                  </li>
                  <li>
                    •
                    ユーザーが既に購入または問い合わせた商品と類似した、当社が提供するその他の商品およびサービスに関する情報をユーザーに提供するため。
                  </li>
                  <li>
                    •
                    ユーザーが関心を寄せる可能性があると当社が考える商品またはサービスに関する情報を、ユーザーに提供するため、またはユーザーに提供することを特定の第三者に許可するため。電子的な手段
                    (E メールまたは SMS)
                    による当社からユーザーへのご連絡は、ユーザーへの以前の販売
                    (または販売交渉)
                    の対象であった商品またはサービスに類似する商品およびサービスについての情報を伝える場合や、ユーザーが同意した場合など、当社が合法的にご連絡できる場合のみに限定されます。
                  </li>
                  <li>サービスの変更についてユーザーに通知するため。</li>
                  <li>
                    SparkThink
                    のコンテンツが、ユーザーおよびユーザーのコンピューターに対して最も効果的に提示されるようにするため。
                  </li>
                </ul>
              </p>
              <p>
                視覚障碍をお持ちのユーザーは、スクリーンリーダーやその他のテキスト読み上げツールまたは点訳ツールを使用して、この通知の内容を確認できます。当社では、クロスコンテキスト行動広告を目的としてユーザーの個人情報を販売または共有することはありません。
              </p>
              <p>
                ユーザーの個人情報は、要請されたサービスを提供するために必要な期間
                (ほとんどの場合は 5 年以内) に限って保持されます。Slalom
                が契約上または法律上の義務を遵守するためにユーザーの個人データを使用する必要がなくなった場合、当社は、その個人データを当社のシステムおよび記録から削除するか、ユーザーを特定できないように適切に匿名化するための措置を講じるか、その両方を行います。ただし、当社が、その後、法的に規定された期間、税務、監査、および法令遵守のために、個人データを含むユーザーの情報を保持する必要がある場合、または出訴期間内は当社が証拠を保持する必要がある場合は、この限りではありません。
              </p>
              <p>
                CCPA プライバシーポリシーは、
                <a href="https://www.slalom.com/ja/privacy-policy?lp=1">
                  https://www.slalom.com/ja/privacy-policy?lp=1
                </a>
                でご覧いただけます。
              </p>
            </TextContainer>
            <Subheader id="msdp">
              メキシコデータ保護法に基づく補足的な開示
            </Subheader>
            <TextContainer>
              <p>
                <b>1. 個人情報に関するユーザーの権利</b>
              </p>
              <p>
                <ul>
                  <li>
                    <u>アクセスの権利</u>
                    <br />
                    ユーザーは、ご自身の個人情報が処理されているかどうかを当社に確認し、処理されている場合は、処理された個人情報のカテゴリや処理の目的を含めて、その個人情報へのアクセスを要求する権利を認められることがあります。
                  </li>
                  <li>
                    <u>訂正の権利</u>
                    <br />
                    ユーザーは、ご自身に関する不正確または不完全な個人情報を訂正する権利を認められることがあります。
                  </li>
                  <li>
                    <u>使用中止の権利</u>
                    <br />
                    ユーザーは、ご自身に関する個人情報の使用中止を当社に要請する権利を認められる場合があります。当社は関連する法律に従って、そのような要請を検討します。
                  </li>
                  <li>
                    <u>反対する権利</u>
                    <br />
                    ユーザーは、特定の状況下で、当社によるご自身の個人データの処理に反対する権利を認められる場合があります。当社は関連する法律に従って、そのような要請を検討します。
                  </li>
                  <li>
                    <u>同意を取り消す/撤回する権利。</u>
                    <br />
                    ユーザーには、ご自身の個人情報の処理を許可した当社への許諾を撤回する権利があります。当社は関連する法律に従って、そのような要請を検討します。
                  </li>
                </ul>
              </p>
              <p>
                上記の権利のいずれかを行使する場合や、ご不明な点またはご不満な点については、E
                メール (
                <a href="mailto:privacy@slalom.com">privacy@slalom.com</a>)
                でお問い合わせください。
              </p>
              <p>
                ユーザーからの要請については、適用法に従った対応が行われます。要請があれば、当社からユーザーに以下の情報を提供します。(i)
                当社がユーザーの本人確認のために受け取る必要のある情報と、ユーザーが要請に添付する必要がある書類、(ii)
                ユーザーの要請について、いつまでに当社から回答できるかという情報、(iii)
                要請の提出方法
                (利用可能なテンプレートまたはメカニズムなど)、(iv)
                当社からユーザーに情報をお渡しする際のメディア形式 (E メール、CD
                など)。
              </p>
            </TextContainer>
          </PrivacyContainer>
        </Container>
      </SafeArea>
    </Root>
  )
}
